<template v-slot:activator="{ on, attrs }">
	<div class="formulaire__ticket">
		<svg class="logo" fill="#f9e036" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 125"
			x="0px" y="0px">
			<path
				d="M33.31,53.34Q18.46,68.18,3.62,83a8.64,8.64,0,0,0-2.41,4.05c-0.13.53-.29,1-0.43,1.57v2A24.58,24.58,0,0,0,1.94,94a8.85,8.85,0,0,0,6.72,4.75,1.15,1.15,0,0,1,.27.13h2A11.47,11.47,0,0,0,17,95.58Q30.77,81.78,44.57,68c0.67-.67,1.42-1.28,2.05-1.84l-13-13.1C33.5,53.17,33.4,53.25,33.31,53.34Z" />
			<path
				d="M63.55,42.57l3-3c3.35-3.36,6.69-6.72,10.07-10.05a14.06,14.06,0,0,1,3.58-3c3.88-1.9,7.7-3.94,11.54-5.94a1.21,1.21,0,0,0,.45-0.5c1.39-2.65,2.76-5.32,4.16-8,0.85-1.61,1.72-3.2,2.58-4.81V7c-0.69-.65-1.4-1.28-2.08-1.94Q94.89,3.2,93,1.3A3.75,3.75,0,0,1,92.62.74H92.31c-0.26.17-.51,0.36-0.78,0.5-4,2.06-7.91,4.11-11.85,6.18a1.85,1.85,0,0,0-.73.74c-1.82,3.46-3.6,6.94-5.42,10.41A10.71,10.71,0,0,1,72,21.16c-4.4,4.48-8.87,8.9-13.31,13.34-0.73.73-1.45,1.48-2.15,2.19l6.45,6.42C63.13,43,63.35,42.77,63.55,42.57Z" />
			<path
				d="M78.19,77.39l13-1.41,8.43,8.39A19.14,19.14,0,0,0,73.87,62.85l-0.1-.08c-2.24-2.18-4.45-4.39-6.66-6.6l-7.35-7.35-7.24-7.24-8.17-8.18C41.91,31,39.46,28.52,37,26.05A19.14,19.14,0,0,0,15.92.49L26.08,11.74,23.34,23.89l-13.07.72L0.74,14.06a19.14,19.14,0,0,0,25.61,23c1.36,1.33,2.7,2.69,4,4L42,52.7,55.36,66c2.53,2.53,5.06,5.05,7.56,7.6a0.72,0.72,0,0,1,.1.13,19.14,19.14,0,0,0,22.63,25.4L76.1,89.67Z" />
		</svg>
		<h3 class="mt-10">Nouveau ticket</h3>
		<v-form v-model="opt_form.valide" mode="aggressive" ref="form">
			<InputSearchCab @change="cab_change" class="mt-8" require>
			</InputSearchCab>
			<v-text-field outlined v-model="cab" :rules="opt_form.rules.cab" class="mt-4" v-show="false"
				id="hidden-searchcab"></v-text-field>
			<v-select outlined :items="opt_form.liste_objet" v-model="objet" label="Objet du ticket" item-text="label"
				item-value="code" :rules="opt_form.rules.objet" @change="objectChanged" class="mt-4"></v-select>


			<v-text-field v-if="requireDate == true" v-model="date" label="Date de livraison souhaitée" prepend-icon="mdi-calendar" readonly
				v-bind="attrs" v-on="on"></v-text-field>
			<v-date-picker v-if="requireDate == true" locale="fr" v-model="date" :active-picker.sync="activePicker"
				:min="(new Date(Date.now())).toISOString().substr(0, 10)"></v-date-picker>

			<v-textarea outlined name="input-7-4" label="Contenu du ticket" v-model="content"
				:rules="opt_form.rules.content" class="mt-4"></v-textarea>
			<InputFileUpload colorBack="#3d2f59" class="mt-4 mb-10" @change="files_changes"></InputFileUpload>
			<v-btn class="mt-0" depressed color="#f9e036" :dark="opt_form.valide" :disabled="!opt_form.valide"
				@click="send">
				Envoyer
			</v-btn>
			<v-btn class="ml-4" depressed @click="$router.go(-1);">
				Retour
			</v-btn>
		</v-form>
	</div>
</template>

<script>
import InputSearchCab from '../InputCabSearch.vue'
import InputFileUpload from '../InputFileUpload.vue'
import { mapActions, mapMutations } from 'vuex';

export default {
	name: 'Ticket',
	components: {
		InputSearchCab,
		InputFileUpload
	},
	data: () => ({
		requireDate: false,
		activePicker: null,
		date: null,
		menu: false,
		cab: "",
		objet: "",
		content: "",
		files: [],
		opt_form: {
			liste_objet: [],
			cab_error: false,
			lazy: false,
			valide: false,
			rules: {
				content: [
					v => v != "" ? true : 'Le message est requis',
					v => v.length > 5 ? true : "Le message est trop court"
				],
				objet: [
					v => v != "" ? true : "L'objet est requis"
				],
				cab: [
					v => v != "" ? true : "Le cab est requis",
				]
			}
		},
	}),
	created: function () {
		var _this = this;
		this.getTicketListMotif()
			.then(resultat => {
				_this.opt_form.liste_objet = resultat;
			})
	},
	methods: {
		...mapActions('formulaires', [
			"sendTicket",
			"getTicketListMotif"
		]),
		...mapMutations('formulaires', [
			"setNotif",
			"setNotifMsg"
		]),
		...mapMutations('global', [
			"sendSuccessNotif"
		]),
		cab_change: function (e) {
			this.cab = e;
		},
		files_changes: function (e) {
			this.files = e;
		},
		objectChanged: function(value) {
			if (value == "modif_suite_refus" || value == "modif_prelivraison") {
				this.requireDate = true;
			} else {
				this.requireDate = false;
			}
		},
		send: function () {
			var _this = this;

			_this.opt_form.valide = false;

			this.sendTicket({
				cab: this.cab,
				objet: this.objet,
				dateLiv: this.requireDate ? this.date : "0000-00-00",
				content: this.content,
				files: this.files.map(x => {
					return x.file_id
				})
			})
				.then(() => {
					_this.sendSuccessNotif("Opération effectuée");
					_this.$router.push('/');
				})
		}
	},
};
</script>

<style lang="scss">
.formulaire__ticket {
	width: 40%;
	border: 4px solid #f1f1f1;
	border-radius: 16px;
	margin-top: 30px;
	padding: 20px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: display;
	padding-bottom: 30px;

	.logo {
		width: 50px;
		position: absolute;
		margin-left: auto;
		margin-right: auto;
		top: -25px;
		left: 0;
		right: 0;
		z-index: 1000;
	}
}

@media only screen and (min-width: 701px) and (max-width: 800px) {
	.formulaire__ticket {
		width: 50%;
	}
}

@media only screen and (min-width: 420px) and (max-width: 700px) {
	.formulaire__ticket {
		width: 80%;
	}
}

@media only screen and (min-width: 0px) and (max-width: 419px) {
	.formulaire__ticket {
		width: 90%;
	}
}
</style>